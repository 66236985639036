<template>
  <div class="login-bg">
    <transition name="fade" v-if="toggle">
      <div class="pop-up fixed-top" name="fade">
        <div class="login-box pop-box">
          <img style="width:250px" src="../assets/image/pic/logo_miLearning.png" alt="">
          <font-awesome-icon class="pointer" @click="close" size="2x" :icon="['fa', 'times']"  style="color:rgba(255, 0, 0, .6);float: right;"/>
          <div class="mt-5">
            <form class="mt-4 px-4" v-if="!forget">
              <div class="group">
                <input class="input-text" v-on:keyup.enter="login" v-model="auth.account" type="text" required>
                <span class="highlight"></span>
                <span class="bar"></span>
                <label class="input-text-label">{{ $t('Login.please_enter_your_email') }}</label>
              </div>
              <div class="group">
                <input class="input-text" v-on:keyup.enter="login" v-model="auth.password" type="password" required>
                <span class="highlight"></span>
                <span class="bar"></span>
                <label class="input-text-label">{{ $t('Login.please_enter_password') }}</label>
              </div>
              <div>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" id="checkbox1" v-model="remember">
                  <label class="form-check-label" for="checkbox1">
                    記住我
                  </label>
                </div>
                <button type="button" style="width:100%" class="btn btn-danger mt-2" @click="login">{{ $t('Login.login') }}</button>
              </div>
              <!-- <div class="mt-2" style="text-align: right;">
                <span class="underLine pointer" @click="openForgetPage">
                  {{ $t('Login.forgot_Password') }}
                </span>
              </div> -->
            </form>
            <form class="mt-4 px-4" v-else>
              <div class="group">
                <input class="input-text" v-model="auth.account" type="text" required>
                <span class="highlight"></span>
                <span class="bar"></span>
                <label class="input-text-label">{{ $t('Login.please_enter_your_email') }}</label>
              </div>
              <button type="button" style="width:100%" class="btn btn-danger" @click="getEmail">{{ $t('Login.send_verification_email') }}</button>
            </form>
          </div>
        </div>
      </div>
    </transition>
    <nav class="navbar fixed-top  navbar-light bg-light">
      <img class="pointer" style="width:200px" src="../assets/image/pic/logo_miLearning.png" alt="">
      <div class="mt-2 m-sm-0">
        <!-- <a @click="apply()" target="_blank" class="btn btn-danger mx-2">{{ $t('Login.register') }}</a> -->
        <!-- <a href="https://docs.google.com/forms/d/e/1FAIpQLSe1JBpy2BQEkQoncnMnYOOhDo7p5F96SwM-KjQo8HTodX8mKA/viewform" target="_blank" class="btn btn-danger mx-2">{{ $t('Login.trial') }}</a> -->
        <button type="button" @click="openLogin()" class="btn btn-link">{{ $t('Login.login') }}</button>
      </div>
    </nav>
    <div class="banner">
      <div class="container">
        <div class="row">
          <div class="col-md-8 align-self-center">
            <h3 class="sub-title">生活、商業、CNN 主題情境互動課程</h3>
            <h1 class="title">拓展視野提升英文能力</h1>
            <button type="button" @click="openLogin()" class="btn btn-light mt-4">
              {{ $t('Login.start_my_course') }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="architecture-banner py-5">
      <div class="container">
        <div class="row">
          <div class="mx-auto">
            <h2 class="title">全新學習架構</h2>
          </div>
          <!-- <img style="width:92%; margin-left:3%" src="../assets/image/home/banner02_title.svg" alt=""> -->
          <img style="width:92%; margin-left:3%" src="../assets/image/home/line_R.png" alt="">
        </div>
        <div class="row">
          <div class="col-md-4">
            <img style="width:100%;" src="../assets/image/home/banner02_info_S_1.png" alt="">
          </div>
          <div class="col-md-4">
            <img style="width:100%;" src="../assets/image/home/banner02_info_S_2.png" alt="">
          </div>
          <div class="col-md-4">
            <img style="width:100%;" src="../assets/image/home/banner02_info_S_3.png" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="topic-banner py-5">
      <div class="container">
        <div class="row">
          <div class="mx-auto">
            <h2 class="title">多元學習主題</h2>
          </div>
          <img style="width:92%; margin-left:3%" src="../assets/image/home/line_B.png" alt="">
        </div>
        <div class="row mt-3">
          <div class="col-md-6">
            <img style="width:100%" src="../assets/image/home/banner03_info_life.svg" alt="">
          </div>
          <div class="col-md-6">
            <img style="width:100%" src="../assets/image/home/banner03_info_business.svg" alt="">
          </div>
          <div class="col-md-6">
            <img style="width:100%" src="../assets/image/home/banner03_info_news.svg" alt="">
          </div>
          <div class="col-md-6">
            <img style="width:100%" src="../assets/image/home/banner03_info_toeic.svg" alt="">
          </div>
        </div>
      </div>
    </div>
    <div class="feature-banner py-4">
      <div class="container">
        <div class="row">
          <div class="mx-auto">
            <h2 class="title">課程獨家特色</h2>
          </div>
          <img style="width:92%; margin-left:3%;" src="../assets/image/home/line_R.png" alt="">
          <div class="d-flex carousel mt-4" style="min-width:100px">
            <div class="my-auto" style="width:10%">
              <img class="arrow pointer my-auto" @click="prevSlide" src="../assets/image/icon&btn/icon_arrow/icon_arrow_r_left.png" alt="">
            </div>
            <carousel style="width:80%" ref="carousel" :navigationEnabled="false" :perPageCustom="[[100, 1], [1024, 3]]"  :paginationEnabled="false" navigation-next-label ="❯" navigation-prev-label ="❮">
              <slide class="px-2">
                <div class="card" style="width: 100%">
                  <div class="card text-center">
                    <div class="card-header">
                      雅思學前測驗
                    </div>
                    <img src="../assets/image/home/banner04_info_pic.jpg" class="card-img-top" alt="...">
                    <div class="card-body">
                      <p class="card-text">正式上課前導入雅思模擬線上測驗，精準掌握英語學前落點程度。</p>
                    </div>
                  </div>
                </div>
              </slide>
                    <slide class="px-2">
                    <div class="card" style="width: 100%">
                        <div class="card text-center">
                            <div class="card-header">
                                老師教學講解
                            </div>
                            <img src="../assets/image/home/banner04_info_pic2.jpg" class="card-img-top" alt="...">
                            <div class="card-body">
                                <p class="card-text">專業美籍名師情境教學，教你用英語自信開口，展現專業實力。</p>
                            </div>
                        </div>
                    </div>
                </slide>
                    <slide class="px-2">
                    <div class="card" style="width: 100%">
                        <div class="card text-center">
                            <div class="card-header">
                                每日更新
                            </div>
                            <img src="../assets/image/home/banner04_info_pic3.jpg" class="card-img-top" alt="...">
                            <div class="card-body">
                                <p class="card-text">每日一句掌握熱門話題，全球新聞緊扣世界趨勢，輕鬆從時事中學好英語。</p>
                            </div>
                        </div>
                    </div>
                </slide>
                    <slide class="px-2">
                    <div class="card" style="width: 100%">
                        <div class="card text-center">
                            <div class="card-header">
                                學習成果量化
                            </div>
                            <img src="../assets/image/home/banner04_info_pic4.jpg" class="card-img-top" alt="...">
                            <div class="card-body">
                                <p class="card-text">正式上課前導入多益模擬線上測驗，精準掌握英語學前落點程度。</p>
                            </div>
                        </div>
                    </div>
                </slide>
                    <slide class="px-2">
                    <div class="card" style="width: 100%">
                        <div class="card text-center">
                            <div class="card-header">
                                五大學習模組
                            </div>
                            <img src="../assets/image/home/banner04_info_pic5.jpg" class="card-img-top" alt="...">
                            <div class="card-body">
                                <p class="card-text">影片、文字、跟讀、講解、測驗，結合語音辨識引擎與線上英漢字典，聽說讀寫全面提升。</p>
                            </div>
                        </div>
                    </div>
                </slide>
            </carousel>
              <div class="my-auto" style="width:10%">
                <img class="arrow pointer my-auto" @click="nextSlide" src="../assets/image/icon&btn/icon_arrow/icon_arrow_r_right.png" alt="">
              </div>
          </div>
        </div>
      </div>
      <div class="mt-5 info-bg">
        <div class="container">
          <div class="row">
            <div class="col">
              <div class="info-box">
                <div class="info-title">
                  學習主題
                </div>
                <div>
                  200+ 組
                </div>
              </div>
            </div>
            <div class="col">
              <div class="info-box">
                <div class="info-title">
                  互動課程
                </div>
                <div>
                  1200+ 課
                </div>
              </div>
            </div>
            <div class="col">
              <div class="info-box">
                <div class="info-title">
                  多益模考
                </div>
                <div>
                  20+ 回
                </div>
              </div>
            </div>
            <div class="col">
              <div class="info-box">
                <div class="info-title">
                  標竿企業
                </div>
                <div>
                  100+ 家
                </div>
              </div>
            </div>
            <div class="col">
              <div class="info-box">
                <div class="info-title">
                  學員人數
                </div>
                <div>
                  30000+ 人
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="process-banner">
      <div class="container">
        <div class="row">
          <div class="mx-auto">
            <h2 class="title mt-5">完善學習流程</h2>
          </div>
          <img style="width:92%; margin-left:3%;" src="../assets/image/home/line_B.png" alt="">
        </div>
        <div class="row rwd-hide my-4">
          <img style="width:90%; margin-left:5%;margin-top:50px" src="../assets/image/home/banner05_info1.svg" alt="">
          <img style="width:90%; margin-left:5%;margin-top:100px" src="../assets/image/home/banner05_info2.svg" alt="">
          <img style="width:90%; margin-left:5%;margin-top:100px" src="../assets/image/home/banner05_info3.svg" alt="">
        </div>
        <div class="row my-4 rwd-banner">
          <img class="col-12" src="../assets/image/home/banner05_info_S_1.png" alt="">
          <img class="col-12" src="../assets/image/home/banner05_info_S_2.png" alt="">
          <img class="col-12" src="../assets/image/home/banner05_info_S_3.png" alt="">
        </div>
      </div>
    </div>
    <div class="p-4 feed-back">
      <carousel class="my-4" style="width:180vw; margin-left:-40vw" :navigationEnabled="false" :perPage="3" :paginationEnabled="false" navigation-next-label ="❯" navigation-prev-label ="❮">
        <slide class="px-2">
          <div class="card mb-3 text-left">
            <div class="row no-gutters align-items-center">
              <div class="col-md-2">
                <div class="p-2" style="text-align:center">
                  <img style="width:120px" src="../assets/image/user01.png" alt="...">
                </div>
              </div>
              <div class="col-md-10">
                <div class="card-body">
                  <h5 class="card-title">理財專員</h5>
                  <h5 class="card-title">Thomas Wen</h5>
                  <h6>TOEIC：640</h6>
                  <p class="card-text">每周都會收到企業內學習時數排行榜，知道有其他同學也很認真上課，可以有效激勵想偷懶的我，每週至少要完成一些進度。</p>
                </div>
              </div>
            </div>
          </div>
        </slide>
        <slide class="px-2">
          <div class="card mb-3 text-left">
            <div class="row no-gutters align-items-center">
              <div class="col-md-2">
                <div class="p-2" style="text-align:center">
                  <img style="width:120px" src="../assets/image/user02.png" alt="...">
                </div>
              </div>
              <div class="col-md-10">
                <div class="card-body">
                  <h6 class="card-title">理財專員</h6>
                  <h3 class="card-title">Thomas Wen</h3>
                  <h6>TOEIC：640</h6>
                  <p class="card-text">每周都會收到企業內學習時數排行榜，知道有其他同學也很認真上課，可以有效激勵想偷懶的我，每週至少要完成一些進度。</p>
                </div>
              </div>
            </div>
          </div>
        </slide>
        <slide class="px-2">
          <div class="card mb-3 text-left">
            <div class="row no-gutters align-items-center">
              <div class="col-md-2">
                <div class="p-2" style="text-align:center">
                  <img style="width:120px" src="../assets/image/user03.png" alt="...">
                </div>
              </div>
              <div class="col-md-10">
                <div class="card-body">
                  <h5 class="card-title">業務經理</h5>
                  <h5 class="card-title">Jennifer Chung</h5>
                  <h6>TOEIC：860</h6>
                  <p class="card-text">我出差到大陸時，也可以上網學習，最常看的是簡報、會議、職場溝通社交的課程，很多的句型都能派上用場，就算忙碌，只要想上課的話還是可以做到。</p>
                </div>
              </div>
            </div>
          </div>
        </slide>
        <slide class="px-2">
          <div class="card mb-3 text-left">
            <div class="row no-gutters align-items-center">
              <div class="col-md-2">
                <div class="p-2" style="text-align:center">
                  <img style="width:120px" src="../assets/image/user04.png" alt="...">
                </div>
              </div>
              <div class="col-md-10">
                <div class="card-body">
                  <h5 class="card-title">儀電工程師</h5>
                  <h5 class="card-title">Alex Yang</h5>
                  <h6>TOEIC：450</h6>
                  <p class="card-text">公司很注重員工英文能力，我對語文學習也有興趣，LiveABC課程內容很精彩，每課還有小測驗，每單元兩到三分鐘即可看完一個課程短片，內容簡潔，不會有壓力。   </p>
                </div>
              </div>
            </div>
          </div>
        </slide>
        <slide class="px-2">
          <div class="card mb-3 text-left">
            <div class="row no-gutters align-items-center">
              <div class="col-md-2">
                <div class="p-2" style="text-align:center">
                  <img style="width:120px" src="../assets/image/user05.png" alt="...">
                </div>
              </div>
              <div class="col-md-10">
                <div class="card-body">
                  <h5 class="card-title">軟體開發工程師</h5>
                  <h5 class="card-title">Steven Chou</h5>
                  <h6>TOEIC：520</h6>
                  <p class="card-text">我用搭車的時間反覆訓練聽力，LiveABC會幫我們整理好該課重點片語單字還有發音檔，每天回家複習只要幾分鐘，上課半年來聽力進步很多，我還會繼續上課。</p>
                </div>
              </div>
            </div>
          </div>
        </slide>
      </carousel>
      <carousel  style="width:200vw; margin-left:-50vw" class="my-4" :navigationEnabled="false" :perPage="2" :paginationEnabled="false" navigation-next-label ="❯" navigation-prev-label ="❮">
        <slide class="px-2">
          <div class="card mb-3 text-left">
            <div class="row no-gutters align-items-center">
              <div class="col-md-2">
                <div class="p-2" style="text-align:center">
                  <img style="width:120px" src="../assets/image/user01.png" alt="...">
                </div>
              </div>
              <div class="col-md-10">
                <div class="card-body">
                  <h5 class="card-title">軟體開發工程師</h5>
                  <h5 class="card-title">Steven Chou</h5>
                  <h6>TOEIC：520</h6>
                  <p class="card-text">我用搭車的時間反覆訓練聽力，LiveABC會幫我們整理好該課重點片語單字還有發音檔，每天回家複習只要幾分鐘，上課半年來聽力進步很多，我還會繼續上課。</p>
                </div>
              </div>
            </div>
          </div>
        </slide>
        <slide class="px-2">
          <div class="card mb-3 text-left">
            <div class="row no-gutters align-items-center">
              <div class="col-md-2">
                <div class="p-2" style="text-align:center">
                  <img style="width:120px" src="../assets/image/user02.png" alt="...">
                </div>
              </div>
              <div class="col-md-10">
                <div class="card-body">
                  <h5 class="card-title">軟體開發工程師</h5>
                  <h5 class="card-title">Steven Chou</h5>
                  <h6>TOEIC：520</h6>
                  <p class="card-text">我用搭車的時間反覆訓練聽力，LiveABC會幫我們整理好該課重點片語單字還有發音檔，每天回家複習只要幾分鐘，上課半年來聽力進步很多，我還會繼續上課。</p>
                </div>
              </div>
            </div>
          </div>
        </slide>
        <slide class="px-2">
          <div class="card mb-3 text-left">
            <div class="row no-gutters align-items-center">
              <div class="col-md-2">
                <div class="p-2" style="text-align:center">
                  <img style="width:120px" src="../assets/image/user03.png" alt="...">
                </div>
              </div>
              <div class="col-md-10">
                <div class="card-body">
                  <h5 class="card-title">軟體開發工程師</h5>
                  <h5 class="card-title">Steven Chou</h5>
                  <h6>TOEIC：520</h6>
                  <p class="card-text">我用搭車的時間反覆訓練聽力，LiveABC會幫我們整理好該課重點片語單字還有發音檔，每天回家複習只要幾分鐘，上課半年來聽力進步很多，我還會繼續上課。</p>
                </div>
              </div>
            </div>
          </div>
        </slide>
        <slide class="px-2">
          <div class="card mb-3 text-left">
            <div class="row no-gutters align-items-center">
              <div class="col-md-2">
                <div class="p-2" style="text-align:center">
                  <img style="width:120px" src="../assets/image/user04.png" alt="...">
                </div>
              </div>
              <div class="col-md-10">
                <div class="card-body">
                  <h5 class="card-title">軟體開發工程師</h5>
                  <h5 class="card-title">Steven Chou</h5>
                  <h6>TOEIC：520</h6>
                  <p class="card-text">我用搭車的時間反覆訓練聽力，LiveABC會幫我們整理好該課重點片語單字還有發音檔，每天回家複習只要幾分鐘，上課半年來聽力進步很多，我還會繼續上課。</p>
                </div>
              </div>
            </div>
          </div>
        </slide>
      </carousel>
    </div>
    <!-- <div class="container p-5">
      <div class="row">
        <div class="col-md my-2">
          <div class="card text-center apply-box">
            <div class="card-body">
              <h3 class="card-title">個人</h3>
              <p class="card-text">我想了解課程</p>
              <p class="card-text">請提供個人試用</p>
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSe1JBpy2BQEkQoncnMnYOOhDo7p5F96SwM-KjQo8HTodX8mKA/viewform" target="_blank" class="btn btn-light">{{ $t('Login.trial') }}</a>
            </div>
          </div>
        </div>
        <div class="col-md my-2">
          <div class="card text-center apply-box">
            <div class="card-body">
              <h3 class="card-title">個人</h3>
              <p class="card-text">我決定加入</p>
              <p class="card-text">立刻提升英文能力</p>
              <a @click="apply()" target="_blank" class="btn btn-light">{{ $t('Login.register') }}</a>
            </div>
          </div>
        </div>
        <div class="col-md my-2">
          <div class="card text-center apply-box" style="background: #1A52A6">
            <div class="card-body">
              <h3 class="card-title">企業培訓單位</h3>
              <p class="card-text">我想為企業導入課程</p>
              <p class="card-text">或參考更多英文培訓方案</p>
              <a href="https://docs.google.com/forms/d/e/1FAIpQLSd2qmN7cWkPDjuJFCF3AS0AFN6Syn_j_g6fU6Qw3eqAO-APHQ/viewform" target="_blank" class="btn btn-light">企業諮詢</a>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>
<script>
// @ is an alias to /src
// import Video from '@/components/Video.vue'
import api from '../store/api'
import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'Login',
  data () {
    return {
      forget: false,
      toggle: false,
      auth: {
        account: '',
        password: ''
      },
      remember: window.localStorage.getItem('account') ? true : false
    }
  },
  props: {
    assistData: {
    }
  },
  computed: {
    ...mapGetters([
      'isLoading',
      'authorized',
      'authInfo'
    ]),
    accessRole () {
      if (this.$store.state.auth.user.aiOnly === true) {
        return false
      }else {
        return true
      }
    }
  },
  watch: {
  },
  mounted () {
    if (this.remember) {
      this.auth.account = window.localStorage.getItem('account')
    }
    if (this.authorized) {
      this.$router.push({ path: 'home' })
    }
  },
  methods: {
    ...mapActions({
      dologin: 'login',
      getUser: 'getUser'
    }),
    nextSlide() {
      this.$refs.carousel.goToPage(this.$refs.carousel.getNextPage());
    },
    prevSlide() {
      this.$refs.carousel.goToPage(this.$refs.carousel.getPreviousPage());
    },
    openLogin () {
      this.toggle = true
      this.forget = false
    },
    close () {
      this.toggle = false
      this.forget = false
    },
    openForgetPage () {
      this.forget = true
      this.auth.account = ''
    },
    async login () {
      const vm = this
      try {
        await this.dologin({ email: this.auth.account, password: this.auth.password })
        await this.getUser()
        if (this.remember) {
          window.localStorage.setItem('account', vm.auth.account)
        } else {
          window.localStorage.removeItem('account')
        }
        // if (this.authInfo.user.isFirstLogin) {
        //   this.$router.push({ name: 'First-login' })
        // } else {
        //   this.$router.push({ name: 'Home' })
        // }
        if (this.accessRole) {
          this.$router.push({ name: 'Home' })
        } else {
          this.$router.push({ name: 'ai_assistantIntro' })
        }
      } catch (error) {
        if (error) {
          this.setTimeout(
            this.$swal({
            title: '登入失敗',
            icon: 'error',
            showCancelButton: false,
            confirmButtonText: '確定'
          })
          , 1000)
        }
      }
    },
    async apply () {
      this.$swal({
        title: '輸入報名代碼',
        input: 'text',
        inputPlaceholder: '輸入報名代碼',
        inputAttributes: {
          maxlength: 20,
          autocapitalize: 'off',
          autocorrect: 'off'
        }
      }).then(value => {
        if (value.value){
          window.open(`https://store.liveabc.com/ent/registration/livelearning/${value.value}/signup`, "_blank")
        }
      })
    },
    async getEmail () {
      try {
        const {data} = await api.post(`password/email?email=${this.auth.account}`)
        this.$swal({
          title: data.email || data.status,
          icon: 'info',
          showCancelButton: false,
          confirmButtonText: '確定'
        })
      } catch (error) {
        this.$swal({
          title: error.response.data.errors.email[0],
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: '確定'
        })
      }
    }
  },
  components: {
    // Video
  }
}
</script>
<style lang="scss" scoped>
@import "../assets/styles/custom.scss";
  .login-bg {
    position: relative;
  }
  .group {
    position:relative;
    margin-bottom: 35px;
  }
  .input-text {
    font-size: 18px;
    padding: 10px 10px 10px 5px;
    display: block;
    width: 100%;
    border: none;
    border-bottom: 1px solid #757575;
  }
  .input-text:focus	{ outline: none; }

  /* LABEL ======================================= */
  .input-text-label {
    color: #999;
    font-size: 18px;
    font-weight: normal;
    position: absolute;
    pointer-events: none;
    left: 5px;
    top: 10px;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }

  /* active state */
  .input-text:focus ~ label, input:valid ~ label {
    top: -20px;
    font-size: 14px;
    color: #000000;
  }
  .arrow {
    width: 50px;
  }
  /* BOTTOM BARS ================================= */
  .bar {
    position:relative;
    display:block;
    width:100%;
  }
  .bar:before, .bar:after {
    content: '';
    height: 2px;
    width: 0;
    bottom: 1px;
    position: absolute;
    background: #000000;
    transition: 0.2s ease all;
    -moz-transition: 0.2s ease all;
    -webkit-transition: 0.2s ease all;
  }
  .bar:before {
    left: 50%;
  }
  .bar:after {
    right: 50%;
  }

  /* active state */
  input:focus ~ .bar:before, input:focus ~ .bar:after {
    width: 50%;
  }
.underLine {
  text-decoration: underline;
}
  .title {
    font-weight: bolder;
  }
  .banner {
    height: 65vh;
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    -moz-background-size: cover;
    -webkit-background-size: cover;
    -o-background-size: cover;
    background-image: url('../assets/image/home/banner01_bg_W.png');
    .container {
      height: 70vh;
      .row {
        margin-top: 30px;
        text-shadow: black 0.1em 0.1em 0.2em !important;
        color: #fff;
        text-align: left;
        height: 100%;
        h1 {
          font-size: 4rem;
        }
      }
    }
  }
  .topic-banner {
    background-size: cover;
    background-image: url('../assets/image/home/banner03_bg.jpg');
  }
  .architecture-banner {
    background-size: cover;
    background-image: url('../assets/image/home/banner02_bg.jpg');
  }
  .feature-banner {
    // height: 50vh;
    background-size: contain;
    background-image: url('../assets/image/home/banner02_bg.jpg');
    .card {
      min-height: 350px;
      box-shadow: 3px 3px 5px rgb(141, 141, 141);
      .card-img-top {
        border: #fff 12px solid;
      }
      .card-header {
        background-color: #E82449;
        font-weight: bold;
        font-size: 1.5rem;
        color: #fff;
      }
    }
    .info-box {
      white-space:nowrap;
      font-weight: bolder;
      .info-title {
        color: red;
      }
      div	{
        padding: 10px;
        background-color: #fff;
        margin: 5px;
      }
    }
  }
  .process-banner {
    // height: 80vh;
    background-size: cover;
    background-image: url('../assets/image/home/banner05_bg.jpg');
  }
  .apply-box {
    background-color: #E82449;
    color: #fff;
    h3 {
      font-weight: bolder;
      font-size: 2rem;
    }
  }
  .rwd-banner {
    display: none;
  }
  .feed-back {
    overflow:hidden;
    background-color: $light-color;
  }
  .info-bg {
    padding: 20px;
    background-color: #FFB0A5;
  }
  .pop-up {
    text-align: left;
    width: 100%;
    height: 103vh;
    background-color: #000000ad !important;
    z-index: 1040;
    .login-box {
      @include center();
      padding: 40px;
      border-radius: 20px;
      box-shadow: 10px;
      background-color: #fff;
      width: 580px;
      max-height: 580px;
      box-shadow: 10px 12px 12px 12px rgba(0, 0, 0, 0.4);
      z-index: 1000;
      margin: auto;
    }
  }
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
@media screen and (max-width: 601px) {
  .login-box {
    width: 380px !important;
  }
  .rwd-banner {
    display: block !important;
  }
  .banner {
    background-attachment: scroll;
    background-image: url('../assets/image/home/rwd-bg.jpg');
    // background-position: bottom;
    .title {
      font-size: 2.5rem !important;
    }
    .sub-title {
      font-size: 1.25rem !important;
    }
  }
  .pop-up {
    width: 100%;
  }
}
</style>
